(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/text-expander/assets/javascripts/text-expander.js') >= 0) return;  svs.modules.push('/components/lb-ui/text-expander/assets/javascripts/text-expander.js');

'use strict';

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  TextShortener
} = svs.components.lbUi.textExpander;
const {
  ReactIcon
} = svs.ui;
class TextExpander extends React.Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "state", {
      isOpened: false
    });
    _defineProperty(this, "handleToggleText", e => {
      e.stopPropagation();
      this.setState(_ref => {
        let {
          isOpened
        } = _ref;
        return {
          isOpened: !isOpened
        };
      });
    });
  }
  render() {
    const {
      isOpened
    } = this.state;
    return React.createElement(TextShortener, {
      className: "text-expander".concat(!isOpened ? '' : ' text-expander-is-open'),
      isCollapsed: !isOpened,
      maxLength: this.props.maxLength,
      onClick: this.handleToggleText,
      text: this.props.description
    }, isDotted => isDotted ? React.createElement("button", {
      className: "text-expander-arrow qa-text-expander-arrow",
      onClick: this.handleToggleText,
      type: "button"
    }, React.createElement(ReactIcon, {
      icon: "menu-down",
      size: "200"
    })) : null);
  }
}
_defineProperty(TextExpander, "defaultProps", {
  description: ''
});
svs.components.lbUi.textExpander.TextExpander = TextExpander;

 })(window);